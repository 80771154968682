import React from 'react'
import type { paginationAndSortingParams } from '@/components/pagination/paginationHeader.tsx'
import { usePagination } from '@/hooks/use-pagination.ts'
import { useSorting } from '@/hooks/use-sorting.ts'
import { useQuery } from '@tanstack/react-query'
import { QueryKeys } from '@/constants/QueryKeys.ts'
import { getIngestionFeed, IngestionFeedEventItem } from '@/api/ingestion.ts'
import { ColumnDef } from '@tanstack/react-table'
import { DefaultDataTable } from '@/components/DefaultDataTable.tsx'
import { getAllProjects } from '@/api/projects.ts'
import AiriaIcon from '@/assets/icons/airia-icon.svg?react'
import IngestionStatusBadge from '@/components/home/ingestion/ingestionStatusBadge.tsx'
import ProviderIcon from '@/components/modelLibrary/ProviderIcon.tsx'
import millify from 'millify'

export interface IngestionTableProps {
    queryParams: paginationAndSortingParams
}

export const IngestionTable: React.FC<IngestionTableProps> = ({ queryParams }) => {
    const { limit, onPaginationChange, paginationState } = usePagination({
        initialPageIndex: queryParams.pageNumber ? queryParams.pageNumber - 1 : 0,
        initialPageSize: queryParams.pageSize,
    })
    const { sortingState, onSortingChange } = useSorting({
        initialField: queryParams.sortBy ?? 'createdAt',
        initialOrder: queryParams.sortDirection ?? 'DESC',
    })

    const ingestionEventQuery = useQuery({
        queryKey: [QueryKeys.INGESTION_FEED_ALL, queryParams],
        queryFn: getIngestionFeed,
    })
    const projectsQuery = useQuery({
        queryKey: [QueryKeys.PROJECTS],
        queryFn: () => getAllProjects(),
    })

    const columns: ColumnDef<IngestionFeedEventItem>[] = [
        {
            accessorKey: 'createdAt',
            header: 'Timestamp',
            cell: ({ row }) => {
                const date = new Date(row.original.createdAt)
                return (
                    <span
                        className={
                            'whitespace-nowrap overflow-hidden max-w-[120px] text-ellipsis inline-block'
                        }
                    >{`${date.toLocaleDateString(undefined, { dateStyle: 'short' })} at ${date.toLocaleTimeString(undefined, { timeStyle: 'short' })}`}</span>
                )
            },
        },
        {
            accessorKey: 'projectId',
            header: 'Project',
            cell: ({ row }) => {
                const project = projectsQuery.data?.find(
                    (project) => project.id === row.original.projectId
                )
                return (
                    <div className={'flex gap-1 items-center'}>
                        {project?.projectIcon ? (
                            <img
                                src={project.projectIcon}
                                alt={project.name}
                                height={17}
                                width={17}
                            />
                        ) : (
                            <AiriaIcon className="w-[17px] h-[17px]" />
                        )}
                        <span
                            className={
                                ' whitespace-nowrap overflow-hidden max-w-[200px] text-ellipsis inline-block'
                            }
                        >
                            {project?.name}
                        </span>
                    </div>
                )
            },
        },
        {
            accessorKey: 'dataSourceName',
            header: 'Data Source',
        },
        {
            accessorKey: 'state',
            header: 'Status',
            cell: ({ row }) => <IngestionStatusBadge status={row.original.state} />,
        },
        {
            header: 'Embedding Provider',
            accessorKey: 'name',
            cell: ({ row }) => (
                <div className={'flex gap-1 items-center'}>
                    <ProviderIcon
                        provider={row.original.embeddings.provider}
                        className="w-[17px] h-[17px]"
                    />
                    {row.original.embeddings.provider}
                </div>
            ),
        },
        {
            header: 'Files Processed',
            accessorKey: 'filesProcessed',
            cell: ({ row }) => (
                <div className={'flex gap-4 text-gray-350'}>
                    <div className={'flex flex-col items-center'}>
                        <span className={'text-base text-foreground'}>
                            {row.original.filesProcessed.total}
                        </span>
                        <span>Total</span>
                    </div>
                    {row.original.filesProcessed.successful > 0 && (
                        <div className={'flex flex-col items-center'}>
                            <span className={'text-base text-green-500'}>
                                {row.original.filesProcessed.successful}
                            </span>
                            <span>Success</span>
                        </div>
                    )}
                    {row.original.filesProcessed.failed > 0 && (
                        <div className={'flex flex-col items-center'}>
                            <span className={'text-base text-destructive'}>
                                {row.original.filesProcessed.failed}
                            </span>
                            <span>Failed</span>
                        </div>
                    )}
                </div>
            ),
        },
        {
            header: 'Ingested Data',
            accessorKey: 'filesProcessed',
            cell: ({ row }) => {
                const totalSizeInBytes = row.original.ingestedDataSize.sizeInMb * 1_048_576
                return (
                    <span className={'text-foreground'}>
                        {row.original.ingestedDataSize.numberOfRecords}
                        {' record(s) ('}
                        {millify(totalSizeInBytes, {
                            units: ['B', 'KB', 'MB', 'GB', 'TB'],
                            space: true,
                        })}
                        {')'}
                    </span>
                )
            },
        },
        {
            accessorKey: 'vectorDatabase.databaseType',
            header: 'Database Type',
        },
        {
            accessorKey: 'jobId',
            header: 'Job ID',
        },
    ]

    return (
        <div className={'flex flex-col gap-2 mt-6'}>
            <DefaultDataTable
                data={ingestionEventQuery.data?.items ?? []}
                columns={columns}
                serverPagination
                totalCount={ingestionEventQuery.data?.items.length}
                limit={limit}
                onPaginationChange={onPaginationChange}
                onSortingChange={onSortingChange}
                paginationState={paginationState}
                sortingState={sortingState}
                rowsPerPage={queryParams.pageSize}
            />
        </div>
    )
}
