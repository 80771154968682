import { cn } from '@/lib/utils'
import { Sidebar } from './SideBar'
import { useState } from 'react'

export default function SideNavLayout() {
    const [open, setOpen] = useState(true)
    return (
        <>
            <Sidebar open={open} setOpen={setOpen} />
            <main
                className={cn(
                    'min-h-[calc(100vh_-_56px)] bg-zinc-50 dark:bg-zinc-900 transition-[margin-left] ease-in-out duration-300',
                    open === false ? 'ml-[84px]' : 'ml-56'
                )}
            />
        </>
    )
}
