// en.ts

export const englishTranslation = {
    home: 'Home',
    pipelines: 'Pipelines',
    models: 'Models',
    model_library: 'Model Library',
    datasets: 'Data Sources',
    prompts: 'Prompts',
    api_keys: 'API Keys',
    memory: 'Memory',
    credentials: 'Credentials',
    administration: 'Administration',
    language: 'Language',
    english: 'English',
    spanish: 'Spanish',
    italian: 'Italian',
    portuguese: 'Portuguese',
    french: 'French',
    german: 'German',
    logout: 'Logout',
    toggle_theme: 'Toggle theme',
    error_occurred: 'An error occurred',
    airia_platform: 'Airia Platform',
    login: 'Login',
    uptime: 'Up time',
    costs_per_month: 'Costs / mo',
    prompts_per_month: 'Prompts / mo',
    at: 'at',
    from: 'from',
    by: 'by',
    add_pipeline_step: 'Add Pipeline Step',
    objects: 'Objects',
    model: 'Model',
    dataset: 'Data Source',
    actions: 'Actions',
    pipeline: 'Pipeline',
    code_execution: 'Code Execution',
    recall_memory: 'Recall Memory',
    store_memory: 'Store Memory',
    loading: 'Loading',
    max_number_of_results: 'Max # of Results',
    relevance_percentage: '% Relevance',
    retrieve_neighboring_chunks: 'Retrieve Neighbouring chunks',
    input: 'Input',
    load: 'Load',
    store: 'Store',
    search: 'Search',
    select_a_type: 'Select a type',
    temperature: 'Temperature',
    select_prompt: 'Select prompt...',
    search_prompts: 'Search Prompts...',
    no_model_found: 'No model found.',
    model_include_context: 'Include Context',
    node_info: 'Node Info',
    delete: 'Delete',
    name: 'Name',
    guid: 'GUID',
    step_guid: 'Step GUID',
    api_url: 'API Url',
    api_key: 'API Key',
    copy_key: 'Copy Key',
    key_copied: 'Key Copied!',
    copy_url: 'Copy URL',
    url_copied: 'URL Copied!',
    copy_id: 'Copy ID',
    id_copied: 'ID Copied!',
    output: 'Output',
    version: 'Version',
    error_title: 'Uh oh! Something went wrong.',
    error_description: 'Something went wrong when trying to create the pipeline.',
    pipeline_name: 'Pipeline Name',
    name_validation: 'Name can only contain letters, digits, spaces and the characters - ~ . _',
    other: 'Other',
    toggle: 'Toggle',
    playground: 'Playground',
    try_out_pipeline: 'Try out your pipeline',
    request: 'Request',
    ask_anything: 'Ask me anything...',
    save_as: 'Save As',
    save: 'Save',
    imgAlt: {
        airiaLogo: 'Airia Logo',
        screenshot: 'Screenshot',
        preview: 'preview',
        messageImg: 'message image',
        projectIcon: 'project icon',
    },
    unique_pipeline_name: 'The new pipeline name must be unique.',
    save_new_pipeline: 'Save New Pipeline',
    create_new_pipeline: 'This will create a new pipeline with the current configuration.',
    new_pipeline_name: 'New Pipeline Name',
    python_code_execution: 'Python Code Execution',
    view_api_key: 'View Api Key',
    created_date: 'Created Date',
    error_description_api: 'Something went wrong when trying to delete api key.',
    open_menu: 'Open menu',
    see_api_key: 'See ApiKey',
    delete_api_key: 'Delete ApiKey',
    new_api_key: 'New Api Key',
    create_new_api_key: 'Create New Api Key',
    api_key_new_success: 'Api Key created successfully.',
    api_key_deleted: 'Api Key deleted successfully.',
    key: 'Key',
    create: 'Create',
    data_source: 'Data Source',
    select_data_source: 'Select a data source',
    azure_blob_storage: 'Azure Blob Storage',
    container_name: 'Container Name',
    database: 'Database',
    select_database: 'Select a Database',
    pinecone_api_key: 'Pinecone API Key',
    pinecone_index_name: 'Pinecone Index Name',
    connection_string: 'Connection String',
    embedding_model: 'Embedding Model',
    select_embedding_model: 'Select an Embedding Model',
    chunking_strategy: 'Chunking Strategy',
    select_chunking_strategy: 'Select a Chunking Strategy',
    airia_magic: 'Airia Magic',
    custom: 'Custom',
    markdown: 'Markdown',
    recursive: 'Recursive',
    html: 'HTML',
    token: 'Token',
    character: 'Character',
    chunk_size: 'Chunk Size',
    chunk_overlap: 'Chunk Overlap',
    separator: 'Separator',
    encoding_name: 'Encoding Name',
    reprocess: 'Reprocess',
    update: 'Update',
    loginPage: {
        header: 'Enterprise AI Simplified',
        enterEmail: 'Enter your Email',
        continue: 'Continue',
        newToAiria: 'New to Airia?',
        getStarted: 'Get Started',
        orGetStarted: 'or get started with',
    },
    error_description_model: 'Something went wrong when trying to update the model.',
    columns: {
        name: 'Name',
        executionName: 'Execution Name',
        status: 'Status',
        lastUpdated: 'Last Updated',
    },
    createGroup: 'Create Group',
    groupName: 'Group Name',
    addUsers: 'Add Users',
    suggestedUsers: 'Suggested Users',
    selectedUsers: 'Selected Users',
    data_source_actions: {
        openMenu: 'Open menu',
        label: 'Actions',
        edit: 'Edit Data Source',
        delete: 'Delete Data Source',
    },
    data_source_title: 'Data Sources',
    newDataSource: 'New Data Source',
    createDataset: {
        title: 'Create New Dataset',
        dataSource: 'Data Source',
        selectDataSource: 'Select a data source',
        name: 'Name',
        namePatternTitle:
            'Name can only contain letters, digits, spaces and the characters - ~ . _',
        containerName: 'Container Name',
        database: 'Database',
        selectDatabase: 'Select a Database',
        pineconeApiKey: 'Pinecone API Key',
        pineconeIndexName: 'Pinecone Index Name',
        connectionString: 'Connection String',
        embeddingModel: 'Embedding Model',
        selectEmbeddingModel: 'Select an Embedding Model',
        chunkingStrategy: 'Chunking Strategy',
        selectChunkingStrategy: 'Select a Chunking Strategy',
        airiaMagic: 'Airia Magic',
        custom: 'Custom',
        markdown: 'Markdown',
        recursive: 'Recursive',
        html: 'HTML',
        token: 'Token',
        character: 'Character',
        chunkSize: 'Chunk Size',
        chunkOverlap: 'Chunk Overlap',
        separator: 'Separator',
        encodingName: 'Encoding Name',
        create: 'Create',
        success_description: 'Data Source created successfully.',
        userSpecific: 'User specific data source',
    },
    editMemory: {
        successTitle: 'Success!',
        successDescription: 'Memory updated successfully.',
        errorTitle: 'Uh oh! Something went wrong.',
        errorDescription: 'Something went wrong when trying to update the memory.',
        duplicateNameError: 'A memory with the same name already exists within this project.',
        name: 'Name',
        type: 'Type',
        selectType: 'Select a type',
        submit: 'Submit',
        types: {
            user: 'User',
            conversation: 'Conversation',
        },
    },
    editModel: {
        dialogTitle: 'Edit Model',
        dialogDescription: 'Edit the model configuration.',
        successTitle: 'Success!',
        successDescription: 'Model updated successfully.',
        errorTitle: 'Uh oh! Something went wrong.',
        errorDescription: 'Something went wrong when trying to update the model.',
        displayName: 'Display Name',
        provider: 'Model Provider',
        selectProvider: 'Select a model provider',
        endpoint: 'Model Endpoint',
        apiKey: 'API Key',
        modelName: 'Model Name',
        modelInput: 'Model Input',
        selectType: 'Select a Model Input Type',
        systemPrompt: 'System Prompt',
        selectSystemPrompt: 'Select a System Prompt',
        searchPrompts: 'Search Prompts...',
        noModelFound: 'No model found.',
        submit: 'Submit',
        types: {
            text: 'Text',
            image: 'Image',
        },
        credentials: 'Credentials',
        credentialSelectLabel: 'Select credential',
        selectSourceType: 'Select the type of credential',
        selectCredential: 'Select a credential',
        createCredential: 'Create a new credential',
        credentialTypes: {
            userProvided: 'I have my own key',
            library: 'Use Airia Universal Key',
        },
        priceLabel: 'Dollar amount',
        unitLabel: 'Unit',
        inputTokenPrice: 'Input cost',
        outputTokenPrice: 'Output cost',
    },
    memoryIndex: {
        memory: 'Memory',
        newMemory: 'New Memory',
        openMenu: 'Open menu',
        actions: 'Actions',
        editMemory: 'Edit Memory',
        deleteMemory: 'Delete Memory',
    },
    newMemory: {
        name: 'Name',
        type: 'Type',
        selectType: 'Select a type',
        submit: 'Submit',
        types: {
            user: 'User',
            conversation: 'Conversation',
        },
        duplicateNameError: 'A memory with the same name already exists within this project.',
        generalError: 'Something went wrong while creating the memory.',
        typeRequired: 'Memory type is required.',
        successDescription: 'Memory created successfully.',
        successTitle: 'Success!',
    },
    newModel: {
        dialogTitle: 'Edit Model',
        dialogDescription: 'Edit the model configuration.',
        displayName: 'Display Name',
        provider: 'Model Provider',
        selectProvider: 'Select a model provider',
        endpoint: 'Model Endpoint',
        credentials: 'Credentials',
        credentialSelectLabel: 'Select credential',
        selectSourceType: 'Select the type of credential',
        selectCredential: 'Select a credential',
        createCredential: 'Create a new credential',
        credentialTypes: {
            userProvided: 'I have my own key',
            library: 'Use Airia Universal Key',
        },
        priceLabel: 'Dollar amount',
        unitLabel: 'Unit',
        apiKey: 'API Key',
        modelName: 'Model Name',
        modelInput: 'Model Input',
        selectType: 'Select a Model Input Type',
        systemPrompt: 'System Prompt',
        selectSystemPrompt: 'Select a System Prompt',
        searchPrompts: 'Search Prompts...',
        noModelFound: 'No model found.',
        submit: 'Add',
        types: {
            text: 'Text',
            image: 'Image',
        },
        successDescription: 'Model created successfully.',
        errorDescription: 'Error Creating Model',
        inputTokenPrice: 'Input cost',
        outputTokenPrice: 'Output cost',
    },
    columns_models: {
        name: 'Name',
        executionName: 'Execution Name',
        lastUpdated: 'Last Updated',
    },
    models_actions: {
        openMenu: 'Open menu',
        label: 'Actions',
        edit: 'Edit Model',
        delete: 'Delete Model',
    },
    users_models: {
        firstName: 'First Name',
        lastName: 'Last Name',
        email: 'Email',
        password: 'Password',
        confirmPassword: 'Confirm Password',
        companyName: 'Company Name',
        password_mismatch: 'Passwords do not match',
        fieldRequired: 'This field is required',
        userName: 'Username',
        addGroups: 'Add Groups',
        createNewUser: 'Create New User',
        suggestedGroups: 'Suggested Groups',
        selectedGroups: 'Selected Groups',
        create: 'Create',
        editUser: 'Edit User',
        editGroup: 'Edit Group',
        deleteGroup: 'Delete Group',
        deleteUser: 'Delete User',
        createUserSuccess: 'User created successfully',
        updateUserSuccess: 'User updated successfully',
        updateGroupSuccess: 'Group updated successfully',
    },
    configure_model: 'Configure Model',
    create_new_model: 'New Model',
    text: 'Text',
    image: 'Image',
    save_changes: 'Save changes',
    edit: 'Edit',
    hide: 'Hide',
    success_title: 'Success!',
    pipeline_created_successfully: 'Pipeline created successfully.',
    pipeline_updated_successfully: 'Pipeline updated successfully.',
    error_description_pipeline: 'Something went wrong when trying to update the pipeline.',
    columns_pipelines: {
        name: 'Name',
        status: 'Status',
        version: 'Version',
        modified: 'Modified',
        stats: 'Stats',
        executions: 'Executions',
        tokens_used: "Token's Used",
    },
    pipeline_actions: {
        label: 'Actions',
        edit: 'Edit Pipeline',
        connection_info: {
            button_label: 'Connection Info',
            example_call_description:
                'An example Pipeline Execution API call looks like the following',
            copy_example: 'Copy Example',
            example_copied: 'Example Copied!',
        },
        delete: 'Delete Pipeline',
        export_error: 'Something went wrong when trying to export the pipeline.',
    },
    sort_by: 'Sort by',
    new_pipeline: 'New Pipeline',
    status_ready: 'Ready',
    status_not_ready: 'Not Ready',
    status_failed: 'Failed',
    success_description_prompt: 'Prompt updated successfully.',
    error_description_prompt: 'Something went wrong when trying to update the prompt.',
    select_version: 'Select Version',
    active: 'Active',
    inactive: 'Inactive',
    set_active: 'Set As Active',
    description: 'Description',
    messages: 'Messages',
    add_message: 'Add Message',
    prompt: 'Prompt',
    new_prompt: 'New Prompt',
    active_version: 'Active Version',
    latest_version: 'Latest Version',
    last_modified: 'Modified',
    unknown_date: 'Unknown Date',
    edit_prompt: 'Edit Prompt',
    delete_prompt: 'Delete Prompt',
    delete_prompt_confirmation: 'Are you sure you want to delete this prompt?',
    delete_prompt_error: 'Failed to delete prompt:',
    delete_prompt_success: 'Prompt deleted successfully',
    error_description_create_prompt: 'Failed to create prompt:',
    project: 'Project',
    create_with_model: 'Create project with this model',
    add_to_project: 'Add to project',
    name_this_project: 'How would you like to name this project?',
    which_project: 'Which project would you like to add this item to?',
    projects: 'Projects',
    applicaton_description: 'Create and view projects.',
    project_create: 'Create Project',
    project_view: 'View Project',
    project_edit: 'Edit Project',
    project_delete: 'Delete Project',
    back: 'Back',
    groups: 'Groups',
    users: 'Users',
    user_description: 'Create and manage users.',
    user_create: 'Create User',
    user_view: 'View User',
    user_edit: 'Edit User',
    user_delete: 'Delete User',
    permissions: 'Permissions',
    data_sources: 'Data Sources',
    workbench: 'Workbench',
    executions: 'Executions',
    tokens_used: 'Tokens Used',
    exit: 'Exit',
    add_component: 'Add a Testing Module',
    type_your_message: 'Type your message...',
    prompt_versions: 'Prompt Versions',
    select_a_model: 'Select a Model',
    select_a_prompt: 'Select a Prompt',
    select_a_prompt_version: 'Select a Prompt Version',
    remove_chat_component: 'Remove Chat Component',
    clear_files: 'Clear Files',
    upload_files: 'Upload Files',
    send_message: 'Send Message',
    save_prompt: 'Save Prompt',
    prompt_output: 'Output',
    remove: 'Remove',
    reset: 'Reset',
    credits: {
        threshold_type: 'Threshold Type',
        available_credits: 'available',
        manage_credits: 'Manage Credits',
        available: 'credit(s) available',
        invoicing: 'Invoices',
        pools: 'Pools',
        view_all: 'View All',
        no_pools: 'No Pools Yet',
        no_pools_subheader: 'Once created, your Pools appear here.',
        subheader: {
            get_started: 'Get started by ',
            purchasing: 'purchasing Credits',
            apply_towards: ' to apply towards your pools',
        },
        create_pool: 'Create Pool',
        manage_pool: 'Manage Pool',
        delete_pool: 'Delete Pool',
        save_pool: 'Save Pool',
        new_pool: 'New Pool Name',
        allotted_credits: 'Allotted Credits',
        assigned_projects: 'Assigned Projects',
        create_success_description: 'Pool created successfully.',
        update_success_description: 'Pool updated successfully.',
        update_error_assigned: 'The project is already assigned to another pool.',
        delete_success_description: 'Pool deleted successfully.',
    },
    credits_activity: {
        projects: 'Projects',
        inout: 'In/Out',
        credits: 'Credits',
        billing: 'Billing',
        credits_available: 'Credits available',
        speed: 'Speed',
        timestamp: 'Timestamp',
        back: 'Back to Credits',
        token_usage: 'Token Usage',
        pool_balance: 'Pool Balance',
        usage: 'Usage',
        performance: 'Performance',
    },
    filter: 'Filter',
    marketplace: {
        banner_header: 'Find the perfect LLM for your deployment',
        banner_subheader:
            'Explore over 1000 powerful AI models built by some of the most trusted creators',
        gen_text: 'Generative Text',
        object_detection: 'Object Detection',
        image_to_text: 'Image To Text',
        browse_all_models: 'Browse Models',
        sorted_by: 'Sorted By',
        downloads: 'Downloads',
        likes: 'Likes',
        search_models: 'Search Models',
        type: 'Type',
        model_name: 'Model Name',
        provider: 'Provider',
        source: 'Source',
        all_models: 'All Models',
        chatbot: 'Chatbot',
        speech: 'Speech',
        image: 'Image',
        most_popular_chatbots: 'Most Popular Chatbots',
        see_all: 'See All',
        vision: 'Vision',
        people_using: 'People using this model',
        price_starting: 'Price Starting at',
        price: 'Price',
        free: 'Free',
        select: 'Select',
        select_option: 'Select an Option',
        select_project: 'Select a Project',
    },
    home_page: {
        get_started: "Let's Get Started",
        create_pipeline: 'Create your first pipeline',
        create_pipeline_desc: 'Use our interface to design and customize you AI pipelines.',
        integrate_data: 'Integrate Your Data',
        integrate_data_desc: 'Connect your data sources effortlessly to power your pipelines.',
        explore_templates: 'Explore Templates',
        explore_templates_desc:
            'Kickstart your project with pre-built templates designed for various AI projects.',
        browse_community: 'Browse Community',
        browse_community_desc: 'Find Models, snippets and more to integrate into your pipelines',
        quick_actions: 'Quick Actions',
        action_create_pipeline: 'Create Pipeline',
        action_add_model: 'Add Model',
        action_more_info: 'More Info',
        action_add_data_source: 'Add Data Source',
        action_add_prompt: 'Add Prompt',
        no_traffic: 'No Traffic Yet',
        no_traffic_desc: 'Once pipelines are up and running you can track their performance here',
        no_activity: 'No Recent Activity',
        no_activity_desc:
            'As you start configuring pipelines, your recent activities will appear here for quick access.',
    },
    edit_data_source: 'Edit Data Source',
    edit_data_source_success: 'Data Source updated successfully.',
    delete_data_source: 'Delete Data Source',
    delete_data_source_success: 'Data Source deleted successfully.',
    listModel: {
        modelName: 'Model Name',
        provider: 'Provider',
        credential_type: 'Credential',
        airia_credential: 'Airia',
        custom_credential: 'Custom',
    },
    export: 'Export',
    data_connector: {
        add_connector_heading: 'Select a Document Connector',
        add_connector_subheading: 'Projects and tools to get your docs into Airia',
        details: 'Details',
    },
    terms_and_privacy: {
        title: 'Terms and Privacy',
        terms_of_service: 'Terms of Service',
        privacy_policy: 'Privacy Policy',
        first_section: 'By clicking "Accept", you agree to Airia\'s',
    },
    error_description_generic: 'Something went wrong. Please try again later.',
    accept: 'Accept',
    cancel: 'Cancel',
    and: 'and',
    new_credentials: {
        dialog_title: 'New Credentials',
        dialog_description: 'Create new credentials',
        create_new_credentials: 'Create credential',
        new_credentials: 'New Credentials',
        name: 'Name',
        type: 'Type',
        project: 'Project',
        select_type: 'Select credential type',
        select_project: 'Select project',
        scope_placeholder: 'Tenant-wide or project specific',
        name_placeholder: 'Name your credential',
        scope: 'Scope',
        data: 'Credentials Data',
        success_description: 'Credentials created successfully.',
        warning_description: 'This credential will be shared with all projects within the tenant.',
    },
    edit_credentials: {
        edit: 'Edit Credentials',
        see_credentials: 'See Credentials',
        delete_credentials: 'Delete Credentials',
        success_description: 'Credentials updated successfully.',
        delete_success_description: 'Credentials deleted successfully.',
    },
    feedback: {
        send_feedback: 'Send Feedback',
        help_us: 'Help us improve this page.',
    },
    download_report: 'Download Report',
    reports: 'Reports',
    billing: 'Billing',
    upgrades: 'Upgrades',
    tools: {
        title: 'Tools',
        new_tool: 'New Tool',
        edit_tool: 'Edit Tool',
        tool_handler: 'Tool Handler',
        parameters: 'Parameters',
    },
    side_nav_headers: {
        project: 'PROJECTS',
        libraries: 'LIBRARIES',
        account: 'ACCOUNT',
        available_models: 'Available Models',
        feeds: 'FEEDS',
    },
    workspaces: {
        actions: {
            open_menu: 'Open menu',
            access_control: 'Access Control',
        },
        workspace: 'Workspace',
        workspaces: 'Workspaces',
        workspaces_description: 'Create and view workspaces.',
        workspace_create: 'Create Workspace',
        access_control: 'Access Control',
        workspace_create_success: 'Workspace created successfully.',
        select_workspace: 'Choose a workspace',
        switching_workspace_header: 'Almost there!',
        switching_workspace_message: 'Switching workspaces, please wait.',
        workspace_switched_header: 'Done!',
        workspace_switched_message: 'Ready to access the workspace.',
        access_new_workspace: 'Access workspace',
        error_description: 'Something went wrong when trying to switch the workspace.',
        select_tenant: 'Select Tenant',
    },
    pipeline_builder_options: {
        save_changes: 'Save Changes',
        pipeline: 'Pipeline Options',
    },
    pipeline_version: 'Pipeline Version',
    view_logs: 'View Logs',
    no_data_available: 'No data available',
    version_change_description: 'Add a short description of the change made to the prompt.',
    version_change_description_label: 'Update Description',
    file_upload_success: 'File uploaded successfully.',
    file_update_success: 'File updated successfully.',
    file_delete_success: 'File deleted successfully.',
    file_reprocess_initiated: 'Request submitted to initiate file reprocessing.',
    back_to_library: 'Back to Library',
    confirmation_to_continue: 'Are you sure you want to continue?',
    debug_information: 'Debug Information',
    ai_gateway: {
        title: 'AI Gateway',
        description:
            'Airia supports using a single Universal API as a common interface and handles the complexity of formatting requests to the appropriate destination model providers.',
        link_text: 'Learn more about supported destination model providers',
        provider: 'Provider',
        select_provider: 'Select a provider',
        use_airia_title: 'Use Airia Universal API Key',
        use_airia_description1:
            'Combine management of all of your unique models and providers under one account and one Credit balance.',
        use_airia_description2: 'The Airia Universal API Key is required for intelligent routing.',
        base_url: 'Base URL',
    },
    settings: 'Settings',
    copy_text: 'Copy text',
    download_json: 'Download JSON',
    all_projects: 'All Projects',
    search_project: 'Search Project',
    scope: 'Scope',
    no_project_found: 'No Project Found',
    siem: {
        syslog: 'Syslog',
        enabled: 'Enabled',
        description:
            "Configure syslog to integrate with your Security Information and Event Management (SIEM) system. Enter your SIEM's syslog server address and port to enable real-time log collection and analysis for enhanced security monitoring.",
        learn_more: 'Learn more about SIEM',
        server_url: 'Server URL',
        server_url_placeholder: 'Paste URL Here',
        protocol: 'Protocol',
        use_tls: 'Use TLS',
        additional_tls: 'Some additional text if needed',
        tls_port: 'TLS Port',
        format: 'Format',
    },
    help_center: {
        chat: 'Chat',
        feedback: 'Feedback',
        title: 'AI Chat',
        need_help: 'Have a specific issue? Find answers in our',
        help_center: 'Help Center',
        how_to_help: 'How can I help you today?',
        error: 'Help Center - Error',
        error_description: 'There was an error sending the message, please try again',
    },
    memory_delete_confirmation: 'Are you sure you want to delete this memory?',
    deployment_delete_confirmation: 'Are you sure you want to delete this deployment?',
    memory_delete_success: 'Memory deleted successfully',
    newDeployment: {
        successTitle: 'Success!',
        successDescription: 'Deployment created successfully.',
    },
    api_key_warning: 'This API key will be shared with all projects within the tenant.',
    model_options: {
        context_tooltip:
            'Add context about the present date and time to your messages when chatting with the model.',
    },
    loading_modal: {
        loading: 'Loading...',
        please_wait: 'Please allow a few seconds while we process your request',
    },
    no_prompt_found: 'No prompt found.',
    account: 'Account',
    usage: 'Usage',
    consumption: 'Consumption',
    execution: 'Execution',
    gateway: 'Gateway',
    ingestion: 'Ingestion',
    connectors: 'Connectors',
    view_all_projects: 'View All Projects',
    insights: 'Insights',
    project_delete_success: 'Project deleted successfully',
    project_delete_confirmation: 'Are you sure you want to delete this project?',
}
