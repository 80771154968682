import { type Dataset, DeleteDataset, GetAllDatasets } from '@/api/datasets'
import { ingestData } from '@/api/ingestion'
import { DefaultDataTable } from '@/components/DefaultDataTable'
import PaginationHeader, {
    type paginationAndSortingParams,
} from '@/components/pagination/paginationHeader'
import { Badge } from '@/components/ui/badge'
import { Button } from '@/components/ui/button'
import {
    DropdownMenu,
    DropdownMenuContent,
    DropdownMenuItem,
    DropdownMenuLabel,
    DropdownMenuTrigger,
} from '@/components/ui/dropdown-menu'
import { Toaster } from '@/components/ui/toaster'
import { toast } from '@/components/ui/use-toast.ts'
import { QueryKeys } from '@/constants/QueryKeys.ts'
import { usePagination } from '@/hooks/use-pagination'
import useSearchEffect from '@/hooks/use-search-effect'
import { useSorting } from '@/hooks/use-sorting'
import { HandleDeleteError } from '@/lib/HandleErrors'
import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query'
import {
    Link,
    type SearchSchemaInput,
    createFileRoute,
    useNavigate,
    useRouter,
} from '@tanstack/react-router'
import type { ColumnDef } from '@tanstack/react-table'
import { useDebounce } from '@uidotdev/usehooks'
import {
    Check,
    FileClock,
    Loader2,
    MoreHorizontal,
    Pencil,
    RotateCcw,
    Trash,
    X,
} from 'lucide-react'
import { useEffect, useState } from 'react'
import { useTranslation } from '@/lib/i18n'

export const Route = createFileRoute('/_mainLayout/$projectId/_projectLayout/datasets/')({
    component: AllDatasets,
    pendingComponent: () => {
        return <>loading</>
    },
    validateSearch: (
        search: Record<string, unknown> & SearchSchemaInput
    ): paginationAndSortingParams => {
        return {
            projectId: search.projectId as string,
            pageNumber: search.pageNumber as number,
            pageSize: search.pageSize as number,
            sortBy: search.sortBy as string,
        }
    },
})

function AllDatasets() {
    const { t } = useTranslation()
    const router = useRouter()
    const params = Route.useParams()
    const queryClient = useQueryClient()
    const deleteMutation = useMutation({
        mutationFn: DeleteDataset,
        onSuccess: () => {
            queryClient.invalidateQueries({
                queryKey: [QueryKeys.ALL_DATASETS, params.projectId],
            })
            toast({
                title: t('success_title'),
                description: t('delete_data_source_success'),
            })
        },
        onError: () => {
            toast({
                title: t('error_title'),
                description: t('error_description_generic'),
                variant: 'destructive',
            })
        },
    })
    const navigate = useNavigate()
    const queryParams = Route.useSearch()
    const { limit, onPaginationChange, paginationState } = usePagination({
        initialPageIndex: queryParams.pageNumber ? queryParams.pageNumber - 1 : 0,
        initialPageSize: queryParams.pageSize,
    })
    const { sortingState, onSortingChange, field, order } = useSorting({
        initialField: queryParams.sortBy,
        initialOrder: queryParams.sortDirection ?? 'DESC',
    })

    const [searchValue, setSearchValue] = useState<string>('')
    const debouncedSearchValue = useDebounce(searchValue, 500)
    useSearchEffect({
        debouncedSearchValue,
        navigate,
        queryParams,
        params,
        onPaginationChange,
    })

    const GetAllDatasources = useQuery({
        queryKey: [QueryKeys.ALL_DATASETS, params.projectId, queryParams],
        queryFn: () =>
            GetAllDatasets(
                queryParams.pageSize && queryParams.pageNumber
                    ? { ...queryParams, projectId: params.projectId }
                    : { pageSize: 25, pageNumber: 1, projectId: params.projectId }
            ),
    })

    const handleDeleteDataset = async (datasetId: string) => {
        try {
            await deleteMutation.mutateAsync(datasetId)
        } catch (error) {
            console.error('error deleting dataset', error)
            HandleDeleteError(error, 'datasets')
        }
    }

    const handleReIngest = async (id: string) => {
        try {
            await ingestData(id)
            await queryClient.invalidateQueries({
                queryKey: [QueryKeys.ALL_DATASETS, params.projectId],
            })
        } catch {
            console.error('erroring here')
        }
    }

    const columns: ColumnDef<Dataset>[] = [
        {
            accessorKey: 'name',
            header: t('name'),
        },
        { accessorKey: 'executionName', header: t('columns.executionName') },
        {
            accessorKey: 'state',
            header: t('columns_pipelines.status'),
            cell: ({ row }) => {
                if (
                    row.original.state?.toLowerCase() === 'failed' ||
                    row.original.state?.toLowerCase() === 'unknown'
                ) {
                    return (
                        <div className="flex items-center gap-1">
                            <Badge className="rounded-full bg-error-muted hover:bg-error-muted">
                                <div className="flex flex-row gap-1 items-center text-success-foreground text-xs">
                                    <X />
                                    {row.original.state}
                                </div>
                            </Badge>
                            <Button
                                variant="ghost"
                                className="px-2 py-0 rounded-full"
                                onClick={() => handleReIngest(row.original.id)}
                            >
                                <RotateCcw className="w-5 h-5" />
                            </Button>
                        </div>
                    )
                }
                if (row.original.state?.toLowerCase() !== 'finished') {
                    return (
                        <div className="flex items-center gap-1">
                            {/* <Progress value={getProgressPercentage(row.original.state)} /> */}
                            <Badge className="rounded-full bg-warning-muted hover:bg-warning-muted">
                                <div className="flex flex-row gap-1 items-center text-success-foreground text-xs">
                                    <Loader2 className="h-6 w-6 animate-spin" />
                                    {/*@ts-ignore*/}
                                    {row.original.state}
                                </div>
                            </Badge>
                        </div>
                    )
                }

                return (
                    <Badge className="rounded-full bg-success hover:bg-success">
                        <div className="flex flex-row gap-1 items-center text-success-foreground text-xs">
                            <Check />
                            {t('status_ready')}
                        </div>
                    </Badge>
                )
            },
        },
        {
            accessorKey: 'updatedAt',
            header: t('last_modified'),
            cell: ({ row }) => {
                return <p>{new Date(row.original.updatedAt).toLocaleString()}</p>
            },
        },
        {
            id: 'actions',
            enableHiding: false,
            cell: ({ row }) => {
                return (
                    <DropdownMenu>
                        <DropdownMenuTrigger asChild>
                            <Button variant="ghost" className="h-8 w-8 p-0">
                                <span className="sr-only fixed">{t('open_menu')}</span>
                                <MoreHorizontal className="h-4 w-4" />
                            </Button>
                        </DropdownMenuTrigger>
                        <DropdownMenuContent align="end">
                            <DropdownMenuLabel className="flex gap-1 ">
                                {t('actions')}
                            </DropdownMenuLabel>
                            <Link
                                to="/$projectId/datasets/$datasetId/edit"
                                params={{
                                    datasetId: row.original.id,
                                    projectId: params.projectId,
                                }}
                                search={{ ...queryParams }}
                            >
                                <DropdownMenuItem className="flex gap-1">
                                    <Pencil className="h-4 w-4" />
                                    {t('edit_data_source')}
                                </DropdownMenuItem>
                            </Link>
                            <DropdownMenuItem
                                className="flex gap-1 text-red-500"
                                onClick={async () => {
                                    await handleDeleteDataset(row.original.id)
                                }}
                            >
                                <Trash className="h-4 w-4" />
                                {t('delete_data_source')}
                            </DropdownMenuItem>
                            <Link
                                to={`/$projectId/datasets/$datasetId/logs`}
                                params={{
                                    datasetId: row.original.id,
                                    projectId: params.projectId,
                                }}
                                search={{ ...queryParams }}
                            >
                                <DropdownMenuItem className="flex gap-1">
                                    <FileClock className="h-4 w-4" />
                                    {t('view_logs')}
                                </DropdownMenuItem>
                            </Link>
                        </DropdownMenuContent>
                    </DropdownMenu>
                )
            },
        },
    ]

    useEffect(() => {
        navigate({
            search: {
                ...queryParams,
                pageNumber: paginationState.pageIndex + 1,
                pageSize: limit,
                sortBy: field,
                sortDirection: order,
            },
        })
    }, [paginationState, sortingState, router.history.location])

    return (
        <div className="flex flex-col gap-2 pr-2">
            <div className="flex justify-between items-center">
                <div className="flex gap-2 items-center">
                    <PaginationHeader
                        queryParams={queryParams}
                        filter={searchValue}
                        setFilter={setSearchValue}
                        sortOptions={columns}
                    />
                    <Button
                        variant="outline"
                        className={'text-primary p-2 shadow-none'}
                        onClick={() => {
                            GetAllDatasources.refetch()
                        }}
                    >
                        <RotateCcw />{' '}
                    </Button>
                </div>
                <div className="flex items-center gap-2">
                    <Link
                        to="/datasources"
                        params={{ projectId: params.projectId }}
                        search={{ ...queryParams }}
                    >
                        <Button>{t('newDataSource')}</Button>
                    </Link>
                </div>
            </div>
            <DefaultDataTable
                data={GetAllDatasources.isRefetching ? [] : GetAllDatasources?.data?.items ?? []}
                columns={columns}
                onRowClick={(row: any) => {
                    router.navigate({
                        to: '/$projectId/datasets/$datasetId/edit',
                        params: { datasetId: row.original.id, projectId: params.projectId },
                        search: { ...queryParams },
                    })
                }}
                rowsPerPage={queryParams.pageSize}
                serverPagination
                totalCount={GetAllDatasources.data?.totalCount}
                limit={limit}
                onPaginationChange={onPaginationChange}
                onSortingChange={onSortingChange}
                paginationState={paginationState}
                sortingState={sortingState}
            />

            <Toaster />
        </div>
    )
}
