import type React from 'react'
import { createContext, useState, type ReactNode } from 'react'

interface RecentProjectsContextProps {
    recentProjectIds: string[]
    setRecentProjectIds: React.Dispatch<React.SetStateAction<string[]>>
}

export const RecentProjectsContext = createContext<RecentProjectsContextProps>({
    recentProjectIds: [],
    setRecentProjectIds: () => {
        /*init*/
    },
})

interface RecentProjectsProviderProps {
    children: ReactNode
}

export const RecentProjectsProvider: React.FC<RecentProjectsProviderProps> = ({ children }) => {
    const [recentProjectIds, setRecentProjectIds] = useState<string[]>([])

    return (
        <RecentProjectsContext.Provider value={{ recentProjectIds, setRecentProjectIds }}>
            {children}
        </RecentProjectsContext.Provider>
    )
}
