import { useEffect, useState } from 'react'
import { useQuery } from '@tanstack/react-query'
import { DefaultDataTable } from '@/components/DefaultDataTable'
import type { ColumnDef } from '@tanstack/react-table'
import type { paginationAndSortingParams } from '@/components/pagination/paginationHeader'
import DataFlow from '@/assets/icons/dataflow.svg?react'
import ArrowCircleUp from '@/assets/icons/arrow-circle-up.svg?react'
import { Badge } from '@/components/ui/badge'
import { ProjectSelect } from '@/components/modelLibrary/selectProjectForm.tsx'
import GatewayModal from '@/components/home/feed/GatewayModal'
import { useDebounce } from '@uidotdev/usehooks'
import { usePagination } from '@/hooks/use-pagination'
import { useSorting } from '@/hooks/use-sorting'
import useSearchEffect from '@/hooks/use-search-effect'
import { useNavigate, useRouter } from '@tanstack/react-router'
import { type StepExecutionContext, getFeed, type FeedResult } from '@/api/feed'
import ExecutionModal from '@/components/home/feed/ExecutionModal'
import { cn } from '@/lib/utils'
import ProviderIcon from '@/components/modelLibrary/ProviderIcon'
import GatewayIcon from '@/assets/icons/log-out.svg?react'
import { QueryKeys } from '@/constants/QueryKeys.ts'

export interface FeedTableProps {
    queryParams: paginationAndSortingParams
    params: { projectId: string }
}

export const FeedTable: React.FC<FeedTableProps> = ({ queryParams, params }) => {
    const [selectedProject, setSelectedProject] = useState<string | undefined>(undefined)
    const [filter, _setFilter] = useState<string>('')
    const [selectedCall, setSelectedCall] = useState<{
        type: string
        data: FeedResult | StepExecutionContext | null
    } | null>(null)
    const [isModalOpen, setIsModalOpen] = useState(false)
    const navigate = useNavigate()
    const router = useRouter()

    const { limit, onPaginationChange, paginationState } = usePagination({
        initialPageIndex: queryParams.pageNumber ? queryParams.pageNumber - 1 : 0,
        initialPageSize: queryParams.pageSize,
    })
    const { sortingState, onSortingChange, field, order } = useSorting({
        initialField: queryParams.sortBy ?? 'createdAt',
        initialOrder: queryParams.sortDirection ?? 'DESC',
    })

    const feedExecutionsQuery = useQuery({
        queryKey: [QueryKeys.EXECUTION_FEED_ALL, selectedProject, queryParams],
        queryFn: () =>
            getFeed(
                //@ts-ignore
                selectedProject != null
                    ? queryParams.pageSize && queryParams.pageNumber
                        ? { ...queryParams, projectId: selectedProject }
                        : { pageSize: 25, pageNumber: 1, projectId: selectedProject }
                    : queryParams.pageSize && queryParams.pageNumber
                      ? { ...queryParams }
                      : { pageSize: 25, pageNumber: 1 }
            ),
    })

    const handleRowClick = (row: { original: FeedResult }) => {
        setSelectedCall({ type: row.original.source, data: row.original })
        setIsModalOpen(true)
    }

    const closeModal = () => {
        setIsModalOpen(false)
        setSelectedCall(null)
    }

    const debouncedSearchValue = useDebounce(filter, 500)
    useSearchEffect({
        debouncedSearchValue,
        navigate,
        queryParams,
        params,
        onPaginationChange,
    })

    const columns: ColumnDef<FeedResult>[] = [
        {
            accessorKey: 'createdAt',
            header: 'Timestamp',
            cell: ({ row }) => {
                const date = new Date(row.original.createdAt)
                return (
                    <p>{`${date.toLocaleDateString(undefined, { dateStyle: 'short' })} at ${date.toLocaleTimeString(undefined, { timeStyle: 'short' })}`}</p>
                )
            },
        },
        {
            header: 'Model',
            accessorKey: 'requestModel',
            cell: ({ row }) => {
                const model = row.original.requestModel
                return (
                    <div className="flex flex-row items-center">
                        <div className="flex flex-col">
                            <div className="flex flex-row gap-[3px] items-center">
                                <ProviderIcon
                                    provider={row.original.providerType}
                                    className="w-6 h-6 mr-1 -ml-2"
                                />
                                <p className="text-gray-550">{row.original.providerType}</p>
                            </div>
                            <p
                                title={model}
                                className="text-[15px] text-gray-450 ml-6 max-w-32 text-ellipsis line-clamp-1"
                            >
                                {model}
                            </p>
                        </div>
                    </div>
                )
            },
        },
        {
            accessorKey: 'source',
            header: 'Source',
            cell: ({ row }) => {
                const executionName = row.original.pipelineExecutionName
                const versionNumber = row.original.pipelineVersionNumber
                if (row.original.source !== 'Gateway') {
                    return (
                        <div className="flex flex-col text-gray-550">
                            <div className="flex flex-row gap-2 items-center">
                                {row.original.source === 'Pipeline' && (
                                    <DataFlow className="w-4 h-4" stroke="currentColor" />
                                )}
                                {row.original.source === 'Playground' && (
                                    <ArrowCircleUp className="w-4 h-4" stroke="currentColor" />
                                )}
                                <p className="text-gray-550">{row.original.source}</p>
                            </div>
                            {row.original.source === 'Pipeline' && (
                                <p className="text-gray-450 ml-[25px]">{`${executionName} - v${versionNumber}`}</p>
                            )}
                            {row.original.source === 'Playground' && executionName && (
                                <p className="text-gray-450 ml-[25px]">{executionName}</p>
                            )}
                        </div>
                    )
                }
                return (
                    <div className="flex flex-row gap-2 items-center">
                        <GatewayIcon className="w-4 h-4" />
                        <p className="text-gray-550">{row.original.source}</p>
                    </div>
                )
            },
        },
        {
            header: 'API Key',
            accessorKey: 'apiKeyName',
            cell: ({ row }) => (
                <p className="text-[15px] text-gray-550">
                    {row.original.apiKeyName !== null && row.original.apiKeyName !== ''
                        ? row.original.apiKeyName
                        : 'AiriaKey'}
                </p>
            ),
        },
        {
            header: 'Response Time',
            accessorKey: 'duration',
            cell: ({ row }) => {
                const parts = row.original.duration.split(':')
                const hours = Number.parseInt(parts[0], 10)
                const minutes = Number.parseInt(parts[1], 10)
                const seconds = Number.parseFloat(parts[2])
                const totalSeconds = hours * 3600 + minutes * 60 + seconds
                const duration = `${totalSeconds.toFixed(2)} seconds`

                return <p className="text-[15px] text-gray-550]">{duration}</p>
            },
        },
        {
            header: 'Total Tokens',
            accessorKey: 'totalTokens',
            cell: ({ row }) => (
                <p className="text-[15px] text-gray-550">{row.original.totalTokens} tokens</p>
            ),
        },
        {
            header: 'Tags',
            accessorKey: 'tags',
            enableSorting: false,
            cell: ({ row }) => {
                if (row.original?.tags)
                    return (
                        <div className="flex gap-2 flex-wrap max-w-[300px] items-center content-center align-middle m-auto">
                            {row.original.tags.map((rawTag) => {
                                const tag = getPrettyTag(rawTag)
                                if (tag !== 'MemoryLoadStep' && tag !== 'MemoryStoreStep')
                                    return (
                                        <Badge
                                            key={tag}
                                            className={cn(
                                                'rounded-[24px] text-sm px-3 py-[2px] w-fit text-gray-550 font-gilroy-medium hover:text-gray-550 justify-center',
                                                tag === 'Streamed' &&
                                                    'bg-primary-100 hover:bg-primary-100',
                                                tag === 'Tools' &&
                                                    'bg-secondary-muted hover:bg-secondary-muted',
                                                tag === 'RAG' && 'bg-error-100 hover:bg-error-100',
                                                tag === 'Python' &&
                                                    'bg-secondary-100 hover:bg-secondary-100'
                                            )}
                                        >
                                            {tag}
                                        </Badge>
                                    )
                            })}
                        </div>
                    )
                if (row.original.source === 'Gateway' && row.original.streamed) {
                    return (
                        <div className="flex gap-2 flex-wrap max-w-[300px] items-center content-center align-middle m-auto">
                            <Badge className="rounded-[24px] text-sm px-3 py-[2px] w-fit text-gray-550 bg-primary-100 hover:bg-primary-100 hover:text-gray-550 justify-center">
                                Streamed
                            </Badge>
                        </div>
                    )
                }
            },
        },
    ]

    // biome-ignore lint/correctness/useExhaustiveDependencies: <explanation>
    useEffect(() => {
        navigate({
            search: {
                ...queryParams,
                pageNumber: paginationState.pageIndex + 1,
                pageSize: limit,
                sortBy: field,
                sortDirection: order,
            },
        })
    }, [paginationState, sortingState, router.history.location])

    return (
        <div className="flex flex-col gap-2 mt-6">
            <div className="flex flex-row justify-between items-center">
                <div className="flex flex-row gap-2">
                    <div className="min-w-48 w-fit">
                        <ProjectSelect
                            selectedProjectId={selectedProject}
                            setSelectedProjectId={setSelectedProject}
                        />
                    </div>
                    {/* <PaginationHeader
                        filter={filter}
                        setFilter={setFilter}
                        sortOptions={columns}
                        queryParams={queryParams}
                    /> */}
                </div>
            </div>
            <DefaultDataTable
                className="text-gray-550"
                data={feedExecutionsQuery.data?.items ?? []}
                columns={columns}
                onRowClick={handleRowClick}
                serverPagination
                totalCount={feedExecutionsQuery.data?.totalCount}
                limit={limit}
                onPaginationChange={onPaginationChange}
                onSortingChange={onSortingChange}
                paginationState={paginationState}
                sortingState={sortingState}
                rowsPerPage={queryParams.pageSize}
            />
            {selectedCall && selectedCall.type === 'Gateway' && (
                <GatewayModal
                    isOpen={isModalOpen}
                    onClose={closeModal}
                    callDetails={selectedCall.data as FeedResult}
                    projectId={selectedProject}
                />
            )}
            {selectedCall &&
                (selectedCall.type === 'Pipeline' || selectedCall.type === 'Playground') && (
                    <ExecutionModal
                        isOpen={isModalOpen}
                        onClose={closeModal}
                        callDetails={selectedCall.data as FeedResult}
                        projectId={selectedProject}
                    />
                )}
        </div>
    )
}

export const getPrettyTag = (tag: string) => {
    const lowerCaseTag = tag.toLocaleLowerCase()
    switch (lowerCaseTag) {
        case 'datasearch':
            return 'RAG'
        case 'pythonstep':
            return 'Python'
        default:
            return tag
    }
}
