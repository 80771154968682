import { ArrowLeftToLine, ArrowRightFromLine } from 'lucide-react'
import { Button } from '@/components/ui/button'
import type { Dispatch, SetStateAction } from 'react'

interface SidebarToggleProps {
    isOpen: boolean | undefined
    setIsOpen?: Dispatch<SetStateAction<boolean>>
}

export function SideNavToggle({ isOpen, setIsOpen }: SidebarToggleProps) {
    return (
        <Button
            onClick={() => setIsOpen?.((prev) => !prev)}
            className="cursor-pointer z-[200] p-0"
            variant="ghost"
            size="icon"
        >
            {isOpen ? <ArrowLeftToLine size={16} /> : <ArrowRightFromLine size={16} />}
        </Button>
    )
}
