import { createFileRoute, SearchSchemaInput } from '@tanstack/react-router'
import { paginationAndSortingParams } from '@/components/pagination/paginationHeader.tsx'
import { IngestionTable } from '@/components/home/ingestion/ingestionTable.tsx'

export const Route = createFileRoute('/_mainLayout/feed/ingestion')({
    component: Ingestion,
    // //@ts-ignore
    validateSearch: (
        search: Record<string, unknown> & SearchSchemaInput
    ): paginationAndSortingParams => {
        return {
            projectId: search.projectId as string,
            pageNumber: search.pageNumber as number,
            pageSize: search.pageSize as number,
            sortBy: search.sortBy as string,
        }
    },
})

function Ingestion() {
    const queryParams = Route.useSearch()
    return <IngestionTable queryParams={queryParams} />
}
