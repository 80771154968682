import React from 'react'
import { IngestionFeedStatus } from '@/api/ingestion.ts'
import { Badge } from '@/components/ui/badge.tsx'
import { Check, Loader, X } from 'lucide-react'

interface IngestionStatusBadgeProps {
    status: IngestionFeedStatus
}

const IngestionStatusBadge: React.FC<IngestionStatusBadgeProps> = ({ status }) => {
    const getBgColor = () => {
        switch (status) {
            case IngestionFeedStatus.Indexing:
            case IngestionFeedStatus.Ingesting:
            case IngestionFeedStatus.Storing:
            case IngestionFeedStatus.Validating:
                return 'bg-warning-muted'
            case IngestionFeedStatus.Finished:
                return 'bg-success'
            case IngestionFeedStatus.Failed:
                return 'bg-error'
            default:
                return 'bg-gray-350'
        }
    }

    const getIcon = () => {
        switch (status) {
            case IngestionFeedStatus.Indexing:
            case IngestionFeedStatus.Ingesting:
            case IngestionFeedStatus.Storing:
            case IngestionFeedStatus.Validating:
                return <Loader className={'h-4 w-4'} />
            case IngestionFeedStatus.Finished:
                return <Check className={'h-4 w-4'} />
            case IngestionFeedStatus.Failed:
                return <X className={'h-4 w-4'} />
            default:
                return <></>
        }
    }

    return (
        <div className={'flex'}>
            <Badge
                className={`flex gap-2 rounded-full ${getBgColor()} hover:${getBgColor()} px-4 py-2 text-foreground hover:text-foreground`}
            >
                {getIcon()}
                {status}
            </Badge>
        </div>
    )
}

export default IngestionStatusBadge
