import { createFileRoute, Outlet, type RoutePaths } from '@tanstack/react-router'
import { TabsList, Tabs } from '@radix-ui/react-tabs'
import { TabLink } from '@/components/ui/tab-link'
import type { routeTree } from '@/routeTree.gen'
import User from '@/assets/icons/user.svg?react'
import UsersIcon from '@/assets/icons/users.svg?react'

export const Route = createFileRoute('/_mainLayout/_administrationLayout')({
    component: AdministrationLayout,
})

function AdministrationLayout() {
    const TABS: {
        path: RoutePaths<typeof routeTree>
        label: string
        icon: JSX.Element
        value: string
    }[] = [
        {
            path: '/administration/users',
            label: 'Users',
            icon: <User className="w-6 h-6 mr-2 -ml-1" />,
            value: 'users',
        },
        {
            path: '/administration/groups',
            label: 'Groups',
            icon: <UsersIcon className="w-6 h-6 mr-2 -ml-1" stroke="currentColor" />,
            value: 'groups',
        },
    ]
    const currentActiveTab = 'users'
    return (
        <div className="flex flex-col gap-4 px-10">
            <Tabs className="flex flex-col" value={currentActiveTab}>
                <TabsList className="shrink-0 flex border-b border-mauve6 w-full gap-10">
                    {TABS.map((config) => (
                        <TabLink key={config.value} {...config} />
                    ))}
                </TabsList>
                <div className="p-1">
                    <Outlet />
                </div>
            </Tabs>
        </div>
    )
}
