import apiRequest from '@/lib/apiClient'

export interface FeedResult {
    id: string
    source: string
    apiKeyName: string
    streamed: boolean
    duration: string
    requestModel: string
    responseModel: string
    providerType: string
    promptTokens: number
    completionTokens: number
    totalTokens: number
    createdAt: string
    clientIpAddress?: string
    userAgent?: string
    pipelineId?: string
    pipelineExecutionName?: string
    pipelineVersionId?: string
    pipelineVersionNumber?: number
    temporary: boolean
    tags?: string[]
    projectId: string
}

export interface StepExecutionContext {
    [key: string]: {
        stepId: string
        success: boolean
    }
}

export interface FeedResultList {
    items: FeedResult[]
    totalCount: number
}

export const getFeed = async (
    queryParams?: Record<string, string | number> | undefined
): Promise<FeedResultList> => {
    if (queryParams?.projectId == null) {
        // biome-ignore lint/performance/noDelete: <explanation>
        delete queryParams?.projectId
    }
    return apiRequest('/Feed', { queryParams })
}

export const getFeedDetails = async (id: string): Promise<StepExecutionContext[]> => {
    return apiRequest(`/Feed/pipelines/${id}`)
}
