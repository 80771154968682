import AiriaLogoBlack from '@/assets/airia-logo-black.svg?react'
import AiriaLogo from '@/assets/airia-logo.svg?react'
import AiriaLogoNoText from '@/assets/airia-logo-no-text.svg?react'
import { cn } from '@/lib/utils'
import { Menu } from '@/components/sidenav/Menu'
import { SideNavToggle } from './SideNavToggle'
import { Link } from '@tanstack/react-router'
import { useTheme } from '@/lib/ThemeProvider'

export function Sidebar({
    open,
    setOpen,
}: { open: boolean; setOpen: React.Dispatch<React.SetStateAction<boolean>> }) {
    const { theme } = useTheme()

    return (
        <aside
            className={cn(
                'fixed top-0 left-0 z-20 h-screen translate-x-0 transition-[width] ease-in-out duration-300 bg-gray-150 ',
                open === false ? 'w-[84px]' : 'w-56'
            )}
        >
            {open && (
                <div className="flex justify-end -mb-5 mr-2 mt-2">
                    <SideNavToggle isOpen={open} setIsOpen={setOpen} />
                </div>
            )}
            <div className="relative h-full flex flex-col px-4 py-6 overflow-y-auto shadow-md dark:shadow-zinc-800 ">
                {open ? (
                    <Link
                        to="/"
                        className="flex rounded-lg items-center text-foreground transition-colors"
                    >
                        {theme === 'dark' ? (
                            <AiriaLogo className="h-9 w-24 self-start" />
                        ) : (
                            <AiriaLogoBlack className="h-9 w-24 self-start" />
                        )}
                    </Link>
                ) : (
                    <Link
                        to="/"
                        className="flex rounded-lg items-center text-foreground transition-colors justify-center"
                    >
                        <AiriaLogoNoText className="h-7 w-7" />
                    </Link>
                )}
                <Menu isOpen={open} setIsOpen={setOpen} />
            </div>
        </aside>
    )
}
