import type { routeTree } from '@/routeTree.gen'
import type { RoutePaths } from '@tanstack/react-router'
import {
    ChartColumn,
    LibraryBig,
    SquareActivity,
    Settings,
    LayoutPanelTop,
    type LucideIcon,
} from 'lucide-react'

export type Submenu = {
    path: RoutePaths<typeof routeTree>
    params?: Record<string, string>
    label: string
    id?: string
}

export type Menu = {
    path?: RoutePaths<typeof routeTree>
    params?: Record<string, string>
    label: string
    icon: LucideIcon
    submenus: Submenu[]
    active: boolean
}

export type MenuGroup = {
    groupLabel: string
    menus: Menu[]
}

export const RECENT_PROJECT_IDS_KEY = 'recentProjectIds'
export const LAST_ACCESSED_PROJECT_KEY = 'lastAccessedProject'

export function getMenuList(path: string): MenuGroup[] {
    return [
        {
            groupLabel: '',
            menus: [
                {
                    path: '/overview',
                    active: path.includes('/overview'),
                    label: 'Insights',
                    icon: ChartColumn,
                    submenus: [],
                },
                {
                    label: 'Projects',
                    icon: LayoutPanelTop,
                    active:
                        path.includes('/projects') ||
                        path.includes('/pipelines') ||
                        path.includes('/models') ||
                        path.includes('/datasets') ||
                        path.includes('/prompts') ||
                        path.includes('/memory') ||
                        path.includes('/tools'),
                    submenus: [],
                },
                {
                    label: 'Libraries',
                    icon: LibraryBig,
                    active: path.includes('/datasources') || path.includes('/library'),
                    submenus: [
                        {
                            path: '/datasources',
                            label: 'Connectors',
                        },
                        {
                            path: '/library',
                            label: 'Models',
                        },
                    ],
                },
                {
                    label: 'Feeds',
                    icon: SquareActivity,
                    active: path.includes('/feed') || path.includes('/consumption'),
                    submenus: [
                        {
                            path: '/feed/execution',
                            label: 'Pipeline Executions',
                        },
                        {
                            path: '/feed/gateway',
                            label: 'Gateway Monitoring',
                        },
                        {
                            path: '/feed/ingestion',
                            label: 'Data Ingestion',
                        },
                        {
                            path: '/feed/consumption',
                            label: 'Token Consumption',
                        },
                    ],
                },
                {
                    label: 'Settings',
                    icon: Settings,
                    active: path.includes('/settings'),
                    path: '/settings/billing',
                    submenus: [],
                },
            ],
        },
    ]
}
