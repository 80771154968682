import { Ellipsis } from 'lucide-react'
import { cn } from '@/lib/utils'
import {
    type MenuGroup,
    type Submenu,
    getMenuList,
    LAST_ACCESSED_PROJECT_KEY,
    RECENT_PROJECT_IDS_KEY,
} from './MenuList'
import { Button } from '@/components/ui/button'
import { ScrollArea } from '@/components/ui/scroll-area'
import { CollapseMenuButton } from './CollapseMenuButton'
import { Tooltip, TooltipTrigger, TooltipContent, TooltipProvider } from '@/components/ui/tooltip'
import { Link, useRouter } from '@tanstack/react-router'
import { SideNavToggle } from './SideNavToggle'
import { useContext, useEffect, useMemo, useState, type Dispatch, type SetStateAction } from 'react'
import { useQuery } from '@tanstack/react-query'
import { type Project, getAllProjects } from '@/api/projects'
import { HandleAuthenticatedError } from '@/lib/HandleErrors'
import { QueryKeys } from '@/constants/QueryKeys'
import { RecentProjectsContext } from './RecentProjectsContext'

interface MenuProps {
    isOpen: boolean | undefined
    setIsOpen: Dispatch<SetStateAction<boolean>>
}

export interface ProjectWithRecent extends Project {
    isRecent: boolean
}

export function Menu({ isOpen, setIsOpen }: MenuProps) {
    const router = useRouter()
    const [menuList, setMenuList] = useState<MenuGroup[]>(
        getMenuList(router.history.location.pathname)
    )

    const AllProjectsQuery = useQuery({
        queryKey: [QueryKeys.PROJECTS],
        queryFn: async () => await getAllProjects().catch((e) => HandleAuthenticatedError(e)),
    })
    const { recentProjectIds, setRecentProjectIds } = useContext(RecentProjectsContext)

    const sortedProjects = useMemo(() => {
        if (!AllProjectsQuery.data) return []

        const recentProjectSet = new Set(recentProjectIds)

        const projectsWithRecent: ProjectWithRecent[] = AllProjectsQuery.data.map((project) => ({
            ...project,
            isRecent: recentProjectSet.has(project.id),
        }))

        return projectsWithRecent.sort((a, b) => {
            if (a.isRecent !== b.isRecent) {
                return a.isRecent ? -1 : 1
            }
            return recentProjectIds.indexOf(a.id) - recentProjectIds.indexOf(b.id)
        })
    }, [AllProjectsQuery.data, recentProjectIds])

    const visibleProjects = sortedProjects.slice(0, 5)

    // biome-ignore lint/correctness/useExhaustiveDependencies: <explanation>
    useEffect(() => {
        const storedRecentProjectIds = localStorage.getItem(RECENT_PROJECT_IDS_KEY)
        if (AllProjectsQuery.isSuccess && AllProjectsQuery.data) {
            if (storedRecentProjectIds) {
                setRecentProjectIds(JSON.parse(storedRecentProjectIds))
            } else {
                const initialRecentProjectIds = AllProjectsQuery.data.slice(0, 5).map((p) => p.id)
                setRecentProjectIds(initialRecentProjectIds)
                localStorage.setItem(
                    RECENT_PROJECT_IDS_KEY,
                    JSON.stringify(initialRecentProjectIds)
                )
            }
            if (localStorage.getItem(LAST_ACCESSED_PROJECT_KEY) === null) {
                localStorage.setItem(LAST_ACCESSED_PROJECT_KEY, AllProjectsQuery.data[0].id)
            }
        }
    }, [AllProjectsQuery.isSuccess, AllProjectsQuery.data, recentProjectIds.length])

    // biome-ignore lint/correctness/useExhaustiveDependencies: <explanation>
    useEffect(() => {
        const baseMenuList = getMenuList(router.history.location.pathname)

        const projectMenuItems = visibleProjects.map((project) => {
            return {
                path: '/$projectId',
                params: { projectId: project.id },
                label: project.name,
                id: project.id,
            } as Submenu
        })

        const newMenuList = baseMenuList.map((group) => {
            if (group.groupLabel === '') {
                group.menus.map((menu) => {
                    if (menu.label === 'Projects') {
                        menu.submenus = projectMenuItems
                    }
                    return menu
                })
            }
            return group
        })

        setMenuList(newMenuList)
    }, [sortedProjects, router.history.location.pathname])

    return (
        <ScrollArea className="[&>div>div[style]]:!block px-1 -my-2">
            <nav className="mt-8 h-full w-full">
                <div className="flex flex-col min-h-[calc(100vh-48px-36px-16px-32px)] lg:min-h-[calc(100vh-32px-40px-32px)] items-start space-y-1 px-2">
                    {menuList.map(({ groupLabel, menus }, index) => (
                        // biome-ignore lint/suspicious/noArrayIndexKey: <explanation>
                        <div className={cn('w-full', groupLabel ? 'pt-5' : '')} key={index}>
                            {(isOpen && groupLabel) || isOpen === undefined ? (
                                <p className="text-sm font-medium text-muted-foreground px-4 pb-2 max-w-[248px] truncate">
                                    {groupLabel}
                                </p>
                            ) : !isOpen && isOpen !== undefined && groupLabel ? (
                                <TooltipProvider>
                                    <Tooltip delayDuration={100}>
                                        <TooltipTrigger className="w-full">
                                            <div className="w-full flex justify-center items-center">
                                                <Ellipsis className="h-5 w-5" />
                                            </div>
                                        </TooltipTrigger>
                                        <TooltipContent side="right">
                                            <p>{groupLabel}</p>
                                        </TooltipContent>
                                    </Tooltip>
                                </TooltipProvider>
                            ) : (
                                <p className="pb-2" />
                            )}
                            {menus.map(({ label, icon: Icon, active, submenus, path }, index) =>
                                submenus.length === 0 ? (
                                    // biome-ignore lint/suspicious/noArrayIndexKey: <explanation>
                                    <div className="w-full" key={index}>
                                        <TooltipProvider disableHoverableContent>
                                            <Tooltip delayDuration={100}>
                                                <TooltipTrigger asChild>
                                                    <Button
                                                        variant={'ghost'}
                                                        className={cn(
                                                            'w-full justify-start flex content-center items-center h-9 hover:bg-primary-100 hover:text-primary px-0',
                                                            isOpen && 'px-2 -ml-2',
                                                            active &&
                                                                !isOpen &&
                                                                'bg-primary-100 text-primary'
                                                        )}
                                                        asChild
                                                    >
                                                        <Link
                                                            className={cn(
                                                                'flex',
                                                                !isOpen && 'justify-center'
                                                            )}
                                                            activeProps={{
                                                                className: path
                                                                    ? 'text-primary bg-primary-100 hover:bg-accent'
                                                                    : '',
                                                            }}
                                                            activeOptions={{
                                                                exact: false,
                                                            }}
                                                            to={path}
                                                        >
                                                            <Icon
                                                                className="self-center"
                                                                size={16}
                                                            />

                                                            {isOpen && (
                                                                <p className="max-w-[200px] truncate translate-x-0 opacity-100 ml-4">
                                                                    {label}
                                                                </p>
                                                            )}
                                                        </Link>
                                                    </Button>
                                                </TooltipTrigger>
                                                {isOpen === false && (
                                                    <TooltipContent side="right">
                                                        {label}
                                                    </TooltipContent>
                                                )}
                                            </Tooltip>
                                        </TooltipProvider>
                                    </div>
                                ) : (
                                    // biome-ignore lint/suspicious/noArrayIndexKey: <explanation>
                                    <div className="w-full my-2" key={index}>
                                        <CollapseMenuButton
                                            icon={Icon}
                                            label={label}
                                            active={active}
                                            submenus={submenus}
                                            isOpen={isOpen}
                                            visibleProjects={visibleProjects}
                                        />
                                    </div>
                                )
                            )}
                            {!isOpen && (
                                <div className="w-full flex items-center justify-center ml-[1px] h-9 my-1">
                                    <SideNavToggle isOpen={isOpen} setIsOpen={setIsOpen} />
                                </div>
                            )}
                        </div>
                    ))}
                </div>
            </nav>
        </ScrollArea>
    )
}
